<template>
  <ReportPage>
    <ReportContent
      :data="data"
      :organization="organization"
      :export-by="exportBy"
      :export-at="exportAt"
    />
  </ReportPage>
</template>

<script>
import ReportPage from '@/components/reports/common/ReportPage'
import ReportContent from '@/components/reports/ScreeningEntity/ReportContent'

export default {
  components: {
    ReportPage,
    ReportContent
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    organization: {
      type: String,
      default: ''
    },
    exportBy: {
      type: String,
      default: ''
    },
    exportAt: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>

</style>
